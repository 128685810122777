"use client";
import React, { useState, useEffect, useRef } from "react";
import ScrollImage from "@/../public/assets/images/bg/top.webp";
import Image from "next/image";
import Link from "next/link";
export default function BackToTop() {
  const [isVisible, setIsVisible] = useState("");
  const prevScrollPos = useRef(0);
  useEffect(() => {
    const toggleVisibility = () => {
      const currentScrollPos = window.scrollY;
      if (currentScrollPos > 200 && currentScrollPos > prevScrollPos.current) {
        setIsVisible("show");
      } else {
        setIsVisible("");
      }
      prevScrollPos.current = currentScrollPos;
    };
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, [isVisible]);
  const scrollButton = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsVisible("");
  };
  return (
    <Link
      onClick={scrollButton}
      className={`button-scroll ${isVisible}`}
      href=""
      target=""
      aria-label="scroll-btn"
    >
      <Image src={ScrollImage} className="img-fluid" alt="scroll image" />
    </Link>
  );
}
