"use client";
import { useState, useEffect } from "react";
import Link from "next/link";
import Image from "next/image";
import { Header as headerData } from "@/data/header";
import Menus from "@/data/menu";

import Menu from "@/components/Menu/Menu";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import { MobileMenu } from "../Menu/MobileMenu";

export default function Header({ params }) {
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <header
        className={`section header-section header-section-2 transparent-header  ${
          isSticky ? "sticky" : ""
        }`}
      >
        <div className="container">
          <div className="header-wrap">
            <div className="header-logo">
              <Link className="logo-black" href={`/${params.locale}`}>
                <Image
                  src={headerData.logo}
                  className="img-fluid"
                  alt="Logo"
                  priority
                />
              </Link>
            </div>
            <Menu data={Menus} params={params} />
            <div className="header-meta">
              <LanguageSwitcher params={params} />
              <div className="header-btn-2 d-none d-xl-block">
                <Link className="btn btn-2" href="downloads">
                  Download
                </Link>
              </div>
              <div className="header-toggle d-lg-none">
                <button
                  onClick={toggleMenu}
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasExample"
                  aria-label="close btn"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>

          <div className="offcanvas offcanvas-start">
            <div className="offcanvas-header">
              <div className="offcanvas-logo">
                <Link href="/" aria-label="offcanvas-logo">
                  <Image src={headerData.mlogo} alt="mlogo" priority />
                </Link>
              </div>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="offcanvas"
                aria-label="Close menu"
              >
                <i className="flaticon-close"></i>
              </button>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu isOpen={isOpen} toggleMenu={toggleMenu} params={params}/>
    </>
  );
}
