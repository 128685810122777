import Logo from "@/../public/assets/images/logo/logo.svg";

const Header = {
  logo: Logo,
  mlogo:Logo
};




export { Header };
