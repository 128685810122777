"use client";
import Link from "next/link";
import useLanguageTranslations from "@/hooks/useLanguageTranslations";

export default function Menu({ data, params }) {
  const translated = useLanguageTranslations(params.locale, "navigation");
  return (
    <>
      {translated && (
        <div className="header-menu d-none d-lg-block">
          {data && data.length > 0 && (
            <ul className="main-menu">
              {data.map((menu, index) => (
                <li key={index}>
                  <Link href={`/${params.locale}${menu.link ?? "#"}`}>
                    {translated(menu.name)}
                  </Link>
                  {menu.subMenus && menu.subMenus.length > 0 && (
                    <>
                      <ul className="sub-menu">
                        {menu.subMenus.map((submenu, subindex) => (
                          <li key={subindex}>
                            {submenu.external && submenu.external === true ? (
                              <Link
                                href={`${submenu.link ?? "#"}`}
                                target="_blank"
                              >
                                {translated(submenu.name)}
                              </Link>
                            ) : (
                              <Link
                                href={`/${params.locale}${submenu.link ?? "#"}`}
                              >
                                {translated(submenu.name)}
                              </Link>
                            )}
                          </li>
                        ))}
                      </ul>
                      <span className="menu-icon" />
                    </>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  );
}
