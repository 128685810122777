"use client";
import { useState } from "react";
// import { useTranslations } from "next-intl";
import styles from './StickyContactButton.module.css';
import ContactForm from "../Form/ContactForm";
import useLanguageTranslations from "@/hooks/useLanguageTranslations";
import Loader from "../Loader/Loader";
export default function StickyContactButton(propes) {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const t = useLanguageTranslations(propes.lang, "contactus");
  if (!t) {
    return <Loader />;
  }
  const togglePopup = () => {
    if (isOpen) {
      setIsClosing(true);
      setTimeout(() => {
        setIsOpen(false);
        setIsClosing(false);
      }, 400);
    } else {
      setIsOpen(true);
    }
  };

  return (
    <>
      <div className={styles.stickyButton} onClick={togglePopup}>
        <label>
          <strong>{t("STR_CONTACT_US")}</strong>
        </label>
      </div>
      {isOpen && (
        <div className={`${styles.popup} ${isClosing ? styles.close : ''}`}>
          <div className={styles.popupHeader}>
            <h5>{t("STR_CONTACT_US")}</h5>
            <button onClick={togglePopup} className={styles.closeButton}>
              &times;
            </button>
          </div>
          <div className={styles.popupBody}>
            <ContactForm fullWidth={true} lang={propes.lang}/>
          </div>
        </div>
      )}
    </>
  );
}
