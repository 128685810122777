export default function Loader({ params }) {
    return(
        <div className="preloader-main">
            <div className="preloader">
                <span></span>
                <span></span>
            </div>
        </div>
    )
}
