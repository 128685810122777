"use client";
import { useState, useEffect } from "react";

const useLanguageTranslations = (locale, modulename) => {
  const [translations, setTranslations] = useState(null);

  useEffect(() => {
    const loadLocaleMessages = async () => {
      let translationFile = `${modulename}.json`;
      try {
        const localeMessages = await import(
          `../../public/locales/${locale}/${translationFile}`
        );
        // console.log(localeMessages)
        setTranslations(() => (key) => localeMessages.default[key] || key);
      } catch (error) {
        console.error("Error loading translation file:", error);
      }
    };

    loadLocaleMessages();
  }, [locale, modulename]);

  return translations;
};

export default useLanguageTranslations;
