import Logo from "@/../public/assets/images/logo/logo.svg";
import PrefredWorkPlace from "@/../public/assets/images/awards/skilrock-most-preferred-workplace-2022-2023.webp";
import LotteryAward from "@/../public/assets/images/awards/EGR-Winner.webp";
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';

// import Mail from "@/../public/assets/imgs/icon/mail-dark.webp";
// import Phone from "@/../public/assets/imgs/icon/phone-dark.webp";

const footer = {
  logo: Logo,
  title: "",
  copyrightText: "STR_SKILROCK_RIGHT_RESERVED",
  companyName: "Skilrock Technologies",
  copyrightDescription: "STR_SKILROCK_FULLY_SUPPORTS",
  copyrightLink: "",
  contact: [
    {
      icon: "flaticon-phone-call",
      name: "+91 124 4231900",
      link: "tel:+911244231900",
    }],
  mail: [
    {
      icon: faEnvelopeOpen,
      name: "marketing@skilrock.com",
      link: "mailto:marketing@skilrock.com",
    },
    {
      icon: faEnvelopeOpen,
      name: "career@skilrock.com",
      link: "mailto:career@skilrock.com",
    },
  ],
  widgets: [
    {
      title: "STR_PRODUCTS",
      menus: [
        {
          name: "STR_INFINITY_PLATFORM",
          link: "lottery-gaming-solutions",
        },
        {
          name: "STR_SMART_RETAIL",
          link: "retail",
        },
        {
          name: "STR_DIGITAL_LOTTERY",
          link: "digital",
        },
        {
          name: "STR_INSTANT_LOTTERY",
          link: "instant-lottery",
        },
        {
          name: "STR_GAMES",
          link: "igaming-games",
        },
        {
          name: "STR_SPORTS_LOTTERY",
          link: "sports-lottery",
        },
        {
          name: "STR_TURNKEY_SERVICE",
          link: "turnkey-solutions",
        },
        {
          name: "STR_PAPER_LOTTERY",
          link: "paper-lottery-solutions",
        },
        {
          name: "STR_LOTTERY_REGULATORY_SYSTEM",
          link: "lottery-regulatory-system",
        },
      ],
    },
    {
      title: "STR_USEFUL_LINK",
      menus: [
        {
          name: "STR_CAREERS",
          link: "https://www.linkedin.com/company/skilrock/jobs/",
        },
        {
          name: "STR_CONTACT_US",
          link: "contact",
        },
        {
          name: "STR_DOWNLOADS",
          link: "downloads",
        },
        {
          name: "STR_COOKIE_POLICY",
          link: "cookie-policy",
        },
        {
          name: "STR_PRIVACY_POLICY",
          link: "privacy-policy",
        },
        {
          name: "STR_EXTRACT_ANNUAL_RETURN",
          link: "/assets/media/pdfs/Form_MGT-7_Skilrock_20-21.pdf",
        },
      ],
    },
  ],
  images: [
    {
      class: "col-lg-3 col-6 col-md-3",
      image: PrefredWorkPlace,
      link: "https://www.youtube.com/watch?v=EeeIdvoK_LU",
      alt: "badge of 'most preferred workplace' award for 2022-23",
    },
    {
      class: "col-lg-2 col-6 col-md-2",
      image: LotteryAward,
      alt: "winner award",
    },
  ],
};

export { footer };
