import enFlag from "@/../public/assets/images/flags/en-gb.svg";
import frFlag from "@/../public/assets/images/flags/fr.svg";
import esFlag from "@/../public/assets/images/flags/es.svg";
const language = [
    {
      code:"en",
      country:"English",
      flag:enFlag
    },
    {
      code:"es",
      country:"Spanish",
      flag:esFlag
    }
  ];



export { language };
