import {
  faInstagram,
  faYoutube,
  faLinkedinIn,
  faFacebookF,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

const socials = [
  {
    icon: faLinkedinIn,
    link: "https://www.linkedin.com/company/skilrock",
  },
  {
    icon: faFacebookF,
    link: "https://www.facebook.com/skilrock/",
  },
  {
    icon: faXTwitter,
    link: "https://twitter.com/skilrock?lang=en",
  },
  {
    icon: faYoutube,
    link: "https://www.youtube.com/channel/UCrixnHAco1z_moIVqq7bxaA",
  },
  {
    icon: faInstagram,
    link: "https://www.instagram.com/skilrock_t/",
  },
];

export default socials;
