"use client";

import { useState, useEffect } from "react";
// import { useTranslations } from "next-intl";
import { contactForm as formdata } from "@/data/contact";
import countries from "i18n-iso-countries";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import classNames from "classnames";
import { showAlert } from "@/utils/showAlert";
import useLanguageTranslations from "@/hooks/useLanguageTranslations";
import Loader from "../Loader/Loader";
export default function ContactForm({ fullWidth, lang }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileno: "",
    interest: "",
    country: "",
    message: "",
    recaptchaToken: "",
  });
  const [countryOptions, setCountryOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const [loading, setLoading] = useState(false); // Loader state

  useEffect(() => {
    // Load country data
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    const countryNames = countries.getNames("en", { select: "official" });
    const options = Object.entries(countryNames).map(([code, name]) => ({
      code,
      name,
    }));
    setCountryOptions(options);

    // Load reCAPTCHA script
    if (!recaptchaLoaded) {
      const script = document.createElement("script");
      script.src =
        "https://www.google.com/recaptcha/api.js?render=6LeHZVYnAAAAAHQhxtvT7Q2sU15PyVLzkwKnul-N";
      script.addEventListener("load", () => {
        grecaptcha.ready(() => {
          setRecaptchaLoaded(true);
          grecaptcha
            .execute("6LeHZVYnAAAAAHQhxtvT7Q2sU15PyVLzkwKnul-N", {
              action: "submit",
            })
            .then((token) => {
              console.log(token);
              setFormData((prev) => ({ ...prev, recaptchaToken: token }));
            });
        });
      });
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [recaptchaLoaded]);
  const t = useLanguageTranslations(lang, "contactus");
  if (!t) {
    return <Loader />;
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      mobileno: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = t("ERROR_NAME_REQUIRED");
    if (!formData.email) newErrors.email = t("ERROR_EMAIL_REQUIRED");
    if (!formData.mobileno) newErrors.mobileno = t("ERROR_MOBILE_REQUIRED");
    if (!formData.interest) newErrors.interest = t("ERROR_INTEREST_REQUIRED");
    if (!formData.country) newErrors.country = t("ERROR_COUNTRY_REQUIRED");
    if (!formData.message) newErrors.message = t("ERROR_MESSAGE_REQUIRED");
    if (!formData.recaptchaToken)
      newErrors.recaptchaToken = t("ERROR_RECAPTCHA_FAILED");
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setErrors({});
      formData.menu_name = "CONTACT_US";
      setLoading(true);
      try {
        // Refresh reCAPTCHA token before submission
        if (recaptchaLoaded) {
          const newToken = await grecaptcha.execute(
            "6LeHZVYnAAAAAHQhxtvT7Q2sU15PyVLzkwKnul-N",
            { action: "submit" }
          );
          setFormData((prev) => ({ ...prev, recaptchaToken: newToken }));
        }

        const response = await fetch("/api/submit-form", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          showAlert(
            "Thank you for sending your details. Our team will get in touch with you shortly"
          );
          setFormData({
            name: "",
            email: "",
            mobileno: "",
            interest: "",
            country: "",
            message: "",
            recaptchaToken: "",
          });
        } else {
          showAlert("Error", "Form submission failed. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        showAlert("Error", "Form submission failed. Please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <div className="contact-form-wrap">
      <form id="contact_form" onSubmit={handleSubmit}>
        <div className="row">
          {/* Name Input */}
          <div
            className={classNames({
              "col-md-6": !fullWidth,
              "col-12": fullWidth,
            })}
          >
            <div className="single-form">
              <input
                className="form-control mt-2"
                type="text"
                placeholder={t("STR_YOUR_NAME")}
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>
          </div>

          {/* Email Input */}
          <div
            className={classNames({
              "col-md-6": !fullWidth,
              "col-12": fullWidth,
            })}
          >
            <div className="single-form">
              <input
                className="form-control mt-2"
                type="email"
                placeholder={t("STR_YOUR_EMAIL")}
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
          </div>

          {/* Mobile Input */}
          <div
            className={classNames({
              "col-md-6": !fullWidth,
              "col-12": fullWidth,
            })}
          >
            <div className="single-form contact">
              <PhoneInput
                international
                country={"us"}
                value={formData.mobileno}
                onChange={handlePhoneChange}
                className="form-control mt-2 contact"
                inputClass="form-phone-input"
              />
              {errors.mobileno && (
                <span className="error">{errors.mobileno}</span>
              )}
            </div>
          </div>

          {/* Interest Select */}
          <div
            className={classNames({
              "col-md-6": !fullWidth,
              "col-12": fullWidth,
            })}
          >
            <div className="single-form interest">
              <select
                className="form-control mt-2 bg_gray_select interest"
                name="interest"
                value={formData.interest}
                onChange={handleChange}
                required
              >
                <option value="">{t("STR_INTERESTED_IN")}</option>
                {formdata.intrested_in.map((optiondata, key) => (
                  <option key={key} value={optiondata.name}>
                    {optiondata.name}
                  </option>
                ))}
              </select>
              {errors.interest && (
                <span className="error">{errors.interest}</span>
              )}
            </div>
          </div>

          {/* Country Select */}
          <div
            className={classNames({
              "col-md-6": !fullWidth,
              "col-12": fullWidth,
            })}
          >
            <div className="single-form country">
              <select
                className="form-control mt-2 bg_gray_select country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
              >
                <option value="">{t("STR_COUNTRY_NAME")}</option>
                {countryOptions.map(({ code, name }) => (
                  <option key={code} value={name}>
                    {name}
                  </option>
                ))}
              </select>
              {errors.country && (
                <span className="error">{errors.country}</span>
              )}
            </div>
          </div>

          {/* Message Input */}
          <div className="col-md-12">
            <div className="single-form">
              <textarea
                className="form-control mt-2 bg_gray_select"
                placeholder={t("STR_PLEASE_MENTION_YOUR")}
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
              {errors.message && (
                <span className="error">{errors.message}</span>
              )}
            </div>
          </div>

          {/* Submit Button */}
          <div className="col-md-12">
            <div className="form-btn text-center mt-2">
              <button className="btn" type="submit">
                {loading ? (
                  <span className="spinner"></span>
                ) : (
                  t("STR_SEND_MESSAGE")
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
