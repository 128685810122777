const Menus = [
  {
    name: "STR_COMPANY",
    link: "/about",
    subMenus: [
      {
        name: "STR_ABOUT_SKILROCK",
        link: "/about",
      },
      {
        name: "STR_MEMBERSHIP_CERTIFICATION",
        link: "/memberships-certifications",
      },
      {
        name: "STR_FAQ",
        link: "/faq",
      },
      {
        name: "STR_DOWNLOADS",
        link: "/downloads",
      },
    ],
  },
  {
    name: "STR_PRODUCT_SOLUTION",
    link: "/about",
    subMenus: [
      {
        name: "STR_INFINITI_PLATFORM",
        link: "/lottery-gaming-solutions",
      },
      {
        name: "STR_SMART_RETAIL",
        link: "/retail",
      },
      {
        name: "STR_DIGITAL_LOTTERY",
        link: "/digital",
      },
      {
        name: "STR_SCAN_PLAY",
        link: "/scan-n-play",
      },
      {
        name: "STR_PAPER_LOTTERY",
        link: "/paper-lottery-solutions",
      },
      {
        name: "STR_LOTTERY_REGULATORY_SYSTEM",
        link: "/lottery-regulatory-system",
      },
      {
        name: "STR_INSTANT_LOTTERY",
        link: "/instant-lottery",
      },
      {
        name: "STR_SPORTS_LOTTERY",
        link: "/sports-lottery",
      },
      {
        name: "STR_TURNKEY_SOLUTIONS",
        link: "/turnkey-solutions",
      },
    ],
  },
  {
    name: "STR_GAMES",
    link: "/games",
    subMenus: [],
  },
  {
    name: "STR_NEWS_EVENTS",
    link: "/",
    subMenus: [
      {
        name: "STR_MEDIA_CENTER",
        link: "/media-center",
      },
      {
        name: "STR_PRESS_RELEASES",
        link: "/press-releases",
      },
      {
        name: "STR_INDUSTRY_NEWS",
        link: "/industry-news",
      },
      {
        name: "STR_BLOGS",
        link: "/blogs",
      },
      {
        name: "STR_GALLERY",
        link: "/gallery",
      },
      {
        name: "STR_EVENTS",
        link: "/events",
      },
    ],
  },
  {
    name: "STR_GET_IN_TOUCH",
    link: "/blogs",
    subMenus: [
      {
        name: "STR_CONTACT_US",
        link: "/contact",
      },
      {
        name: "STR_PARTNERSHIP",
        link: "/partnership",
      },
      {
        name: "STR_CAREERS",
        link: "https://in.linkedin.com/company/skilrock",
        external:true
      },
    ],
  },
];

export default Menus;
